<template>
    <div>
        <!-- SHOW BOOKING POPUP -->
        <booking-preview 
            ref="preview" 
            :bookingPreviewActive.sync="bookingPreviewActive" 
            :currentBooking.sync="currentBooking"
            @updateCurrentBooking="currentBooking = $event"
            @openEditBookingPopUp="editData"
        />

        <booking-form-pop-up 
        :currentBooking.sync="currentBooking"
        @updateCurrentBooking="currentBooking = $event"
        :bookingFormPopUpActive.sync="bookingFormPopUpActive" 
        :isEdit="isBookingEdit" 
        :startDate="startDate" 
        :endDate="endDate" 
        />   

        <booking-confirmation :bookingConfirmationPopUp.sync="bookingConfirmationPopUp" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" @openPreview="bookingPreviewActive = true" :bookingConfirmationType="bookingConfirmationType"/>

        <h4 class="mb-2">{{title}}</h4>

        <vs-table 
        class="mb-5"
        :sst="true" 
        ref="table"
        v-model="selected"
        :pagination="isPagination"
        :search="isSearch"
        :max-items="parseInt(maxItems)" 
        :total="parseInt(total)" 
        :data="bookingsItems"
        @selected="showData"
        >

        <template  slot="thead">
            <template>
                <vs-th v-for="(col, index) in generalInfoColumnsTitles" :key="index" :sort-key="col.sortKey">
                <p class="table-head font-medium truncate">{{ col.title }}</p>
                </vs-th>
            </template>
        </template>
        

        
        <template slot-scope="{data}">
            <tbody>
            <template>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                    <vs-td>
                        <vx-tooltip :text="tr.state.description" >
                            <vs-chip :color="tr.state.color" class="product-order-status"><p class="font-medium truncate">{{ tr.state.display_name}}</p></vs-chip>
                        </vx-tooltip>
                    </vs-td>
                    <vs-td>
                        <p class="product-name font-medium truncate">{{ tr.customer_name }}</p>
                    </vs-td>
                    <vs-td>
                        <p class="product-no font-medium truncate">{{ tr.booking_number }}</p>
                    </vs-td>
                    <vs-td>
                        <vx-tooltip :text="tr.source_description">
                            <vs-chip :color="tr.source_color" class="product-order-status"><p class="font-medium truncate">{{ $t(`bookingSource.${tr.source}`)}}</p></vs-chip>
                        </vx-tooltip>
                    </vs-td>
                    <vs-td>
                        <vx-tooltip :text="tr.created_at" >
                        <p class="booking-created truncate">
                            {{ tr.created_at | moment("DD-MM-YYYY") }}
                        </p>
                        </vx-tooltip>
                    </vs-td>
                    <vs-td>
                        <vx-tooltip :text="tr.start_date" >
                        <p class="booking-date truncate">
                            {{ tr.start_date | moment("DD-MM-YYYY")}}
                        </p>
                        </vx-tooltip>
                    </vs-td>
                </vs-tr>
            </template>
            
            </tbody>
        </template>
        
        </vs-table>
    </div>
</template>

<script>
import BookingFormPopUp from '@/views/q-pages/Booking/BookingFormPopUp.vue'
import BookingConfirmation from '@/views/q-pages/Booking/BookingConfirmation'
export default {
    components: {
        BookingFormPopUp,
        BookingConfirmation
    },
    props: {
        isSearch: {
            type: Boolean,
            default: false
        },
        isPagination: {
            type: Boolean,
            default: false
        },
        maxItems: {
            type: Number,
            default: 5
        },
        total: {
            type: Number,
            default: 5
        },
        bookings: {
            type: Array,
            default(){
                return []
            } 
        },
        title: {
            type: String,
            default: '',
        }
    },
    data () {
        return {
            selected: [],
            generalInfoColumnsTitles : [        
                {title: this.$t('bookingList.category'), key: ""},
                {title: this.$t('bookingList.name'), key: "customer_name"},
                {title: this.$t('bookingList.no'), key: "booking_no"},
                {title: this.$t('bookingList.source'), key: "source"},
                {title: this.$t('bookingList.booking_at'), key: "created_at", sortKey: "created_at"},
                {title: this.$t('bookingUpdatesList.start_date'), key: "start_date", sortKey: "start_date"},
            ],
            bookingPreviewActive: false,
            newBooking: null,
            isBookingEdit: false,
            isBookingShow: false,
            
            // booking from popup
            bookingFormPopUpActive: false,
            currentBooking: null,
            startDate: new Date(),
            endDate: new Date(),
            bookingId: null,
            bookingConfirmationPopUp: false,
            bookingConfirmationType: 'bookingConfirmation',
        }
    },
    computed: {
        bookingsItems () { // an array for organizing the data of the booking
            let bookingsToEdit = JSON.parse(JSON.stringify(this.bookings))
            return bookingsToEdit.map((booking) => {
                if(booking.source == "Admin"){
                    booking.source = "Admin"
                    booking.source_color = "#3B3B3B"
                    booking.source_description = "Booking added by Eventful operating team (admins)"          
                }else if (booking.source == "App" || booking.source == null){
                    booking.source = "Eventful App"
                    booking.source_color = "#FFB113"
                    booking.source_description = "Booking added by a customer using Eventful App"
                }else if (booking.source == "Web App"){
                    booking.source = "Web App"
                    booking.source_color = "#26029E"
                    booking.source_description = "Booking added by a customer using Eventful Web App"
                }else if (booking.source == "SaaS"){
                    booking.source = "SaaS"
                    booking.source_color = "#6a0dad"
                    booking.source_description = "Booking added by the Saas"
                }else if(booking.source == "Agent") {
                    booking.source = "Agent"
                    booking.source_color = "#10163A"
                    booking.source_description = "Booking Added By Agent User (You)"
                }
                return booking
            })
            // return this.bookings;
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
        bookingConfirmationStates() {
            return this.$store.state.bookingStates.bookingConfirmationStates;
        },
        bookingConfirmationStatesNames() {
            return this.bookingConfirmationStates.map(state => state.name);
        }
    },
    created () {
        
        window.addEventListener('changeLanguage', function(e){
        this.$store.dispatch('loader/loaderOn')
        Promise.all([ 
            this.setTableHeagers(),
        ]).then(()=>{this.$store.dispatch('loader/loaderOff')}, this);
        }.bind(this));

        this.$store.dispatch('bookingStates/getBookingStatesArrayByArrayName', {arrayName: 'bookingConfirmationStatesAgent'})

        if(this.$router.history.current.query.booking_id) {
            this.findBookingAndShowData(this.$router.history.current.query.booking_id);
        }

    },
    methods: {
        setTableHeagers() {
            this.generalInfoColumnsTitles = [        
                {title: this.$t('bookingList.no'), key: "booking_no"},
                {title: this.$t('bookingList.name'), key: "customer_name"},
                {title: this.$t('bookingList.category'), key: ""},
                {title: this.$t('bookingList.booking_at'), key: "created_at", sortKey: "created_at"},
                {title: this.$t('bookingUpdatesList.start_date'), key: "start_date", sortKey: "start_date"}
            ];
        },
        showData (data) {
            if(data.cancelation_reason == null)
                data.cancelation_reason = 'unavailable_date';
            this.currentBooking = data;
            this.addBookingIdQueryParameter(this.currentBooking.id);
            let clickedState = data.state.name;
            if(this.bookingConfirmationStatesNames && this.bookingConfirmationStatesNames.includes(clickedState)) {
                this.bookingConfirmationType = clickedState == 'booking_check' ? 'bookingCheck' : 'bookingConfirmation';
                this.bookingConfirmationPopUp = true;
            } else {
                this.bookingPreviewActive = true;
            }
        },
        findBookingAndShowData(bookingId) {
            this.addBookingIdQueryParameter(bookingId);
            let _this = this;
            this.$store.dispatch('dataList/getBooking', {venueId:this.currentVenueId, bookingId: bookingId})
            .then(function(response){
                let isArray = Array.isArray(response);
                const item = isArray? response[0].data.data:response.data.data;
                _this.showData(item)    
                
            })
            .catch(error => {
                this.$vs.notify({
                    color:'danger',
                    title: this.$t('error'),
                    text: error
                })
            })
        },
        closePopup () {
            this.bookingFormPopUpActive = false;
            this.bookingPreviewActive = false;
        },
        editData (data) {
            var booking = {id: data.id, venue_id: parseInt(this.currentVenueId)}
            this.isBookingShow = false;
            this.isBookingEdit = true;
            this.newBooking = booking
            this.bookingId = data.id;
            this.bookingFormPopUpActive = true;
            this.bookingPreviewActive = false;
        },
        addNewData () {
            this.startDate = null;
            this.endDate = null;
            this.currentBooking = {
                start_date: this.startDate,
                end_date: this.endDate,
                receipt: {
                    total_price: 0,
                    discount: 0,
                    percentage_tax: 15,
                    tax: 15,
                    services_total_price: 0,
                    total_price_with_tax: 0,
                    paid_amount: 0
                },
                event_id: null,
                rooms: [],
                option_id: null,
                state_id: null
            }
            this.isBookingEdit = false;
            this.bookingFormPopUpActive = true;
            this.bookingPreviewActive = false;
        },
    }
}
</script>

