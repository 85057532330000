<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <!-- <booking-form-pop-up :bookingFormPopUpActive.sync="bookingFormPopUpActive" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" :isEdit="isBookingEdit" :startDate="startDate" :endDate="endDate" :enableBlockBooking.sync="enableBlockBooking"/> -->

    <div class="vx-row flex justify-between mx-3 mb-5">
      <div class="content-area__heading">
        <h2 >{{$route.meta.pageTitle}}</h2>
      </div>
    </div>

    <!-- CARD 2: BOOKINGS TOTAL -->
    <div class="vx-row">

      <!-- BOOKING ANALYTICS HEADER -->
      <div class="vx-col w-full" color="red">
        
        <!-- error alert -->
        <vs-alert v-if="!!error" class="h-auto" color='danger'>
          {{error}}
        </vs-alert>
        <!--// error alert //-->

        <vx-card class="w-full mb-5 p-3 pb-0 mr-9">
          <div class="vx-row">
            <h2>{{$t('home.BookingsNumbers')}}</h2>
            
          </div>
          <div class="vx-row">
            <h6>{{$t('home.BookingsNumbersDesc')}}</h6>
          </div>

          <vs-divider></vs-divider>
          <!-- DATE PICKER ROW -->
          <div class="vx-row w-full mt-5 items-center"> 
              <div class="vx-col w-full m-0 p-0">
                <div class="mb-4">
                  <v-date-picker
                    v-model="bookingsDateRange"
                    mode="date"
                    is-range
                    color="orange"
                    :masks="dateFormat"
                    :min-date="minDate"
                    :max-date="maxDate"
                  >
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="flex flex-col sm:flex-row justify-between items-center">

                          <vs-input
                            :label="$t('dateRangePicker.from')"
                            class="flex-grow py-1 bg-gray-100 border rounded w-full"
                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            icon="today"
                          />
                          
                          <vs-input
                            :label="$t('dateRangePicker.to')"
                            class="flex-grow py-1 bg-gray-100 border rounded w-full"
                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            icon="event"
                          />
                      </div>
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="vx-col w-full m-0 p-0">
               
                <div class="mb-4">
                  <label class="v-select-label">{{$t('home.chooseBookingsStates')}}</label>
                 
                  <v-select 
                    multiple
                    v-model="selectedStates" 
                    :options="bookingStates" 
                    label="display_name"  
                    name="display_name" 
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" 
                  />
                </div>
              </div>
          </div>
          <!--// DATE PICKER ROW //-->
          <date-buttons @dateClicked="dateClicked"/>

        </vx-card>

      </div>
      <!--// BOOKING ANALYTICS HEADER //-->
      <div class="vx-col w-full mb-5">
        <bookings-line-chart :bookingsDateRange="bookingsDateRange" :selectedStates="selectedStates"/>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-base">
        <statistics-card-line class="h-full" icon="HashIcon" :statistic="bookingAnalytics.bookingsCount"
        :statisticTitle="$t('home.BookingsCount')" 
        :chartData="sales" type="area"
        hideChart>
        </statistics-card-line>
      </div>


      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-base">
        <statistics-card-line  class="h-full" icon="DollarSignIcon" :statistic="bookingAnalytics.totalPriceWithTax"
        :statisticTitle="$t('home.totalPriceWithTax')" 
        :chartData="sales" type="area"
        :extraDescription="bookingAnalytics.totalPrice"
        hideChart>
        </statistics-card-line>
      </div>


      <!-- PIE CHART -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-base">
        <bookings-states-pie-chart :bookingsDateRange="bookingsDateRange" :selectedStates="selectedStates"/>
      </div>
      <!-- // PIE CHART // -->

    </div>
  </div>
</template>

<script>
// import VueApexCharts from 'vue-apexcharts'
import Datepicker from 'vuejs-datepicker'
import DateRangePicker from 'vue2-daterange-picker'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '@/axios'
import moment from 'moment'
import BookingsTable from '@/views/q-pages/Booking/BookingsTable.vue'
import BusinessEventsTable from '@/views/components/qosoor-components/BusinessEvents/BusinessEventsTable.vue'
import BookingsLineChart from '@/views/q-pages/Charts/BookingsLineChart.vue'
import BookingsStatesPieChart from '@/views/q-pages/Charts/BookingsStatesPieChart.vue'
import DateButtons from '@/views/q-pages/DateButtons.vue'
import vSelect from 'vue-select'

export default {
  data () {
    return {
      activeTab: 0,
      currentUser: this.$store.getters.currentUser,
      bookingOptions: [],
      rooms: [],
      sales: [{
          name: "Bookings",
          data: [10,20, 50, 100, 150]
      }],
      venueImages: [],
      bookingsAnalyticsStartDate: "",
      dateRangePicker: {
        
        dateFormat: "d-m-Y"
      },

      error: null,

      // date picker
      bookingsDateRange: {
        start: moment().startOf('month').toDate(),
        end: moment().endOf('month').toDate(),
      },
      minDate: "2019-01-01",
      maxDate: "2025-01-01",
      dateFormat: {
        input: 'YYYY-MM-DD'
      },

      // booking analytics
      bookingAnalytics: {
        totalPaidAmount: "",
        totalPrice: "",
        totalPriceWithTax: "",
        bookingsCount: 0,
        totalQosoorBookings: "",
        totalQosoorCommission: "",
        totalRemainingAmount: ""
      },
      bookingStates: [],
      selectedStates: [],

      currentBookingOptionIsActive: false,
      editBookingOptionActive: false,
      editBookingOptionPromptActive: false,
      currentBookingOption: {},
      isEditBookingOption: false
    }
  },
  components: {
    StatisticsCardLine,
    Datepicker,
    DateRangePicker,
    BookingsTable,
    BusinessEventsTable,
    BookingsLineChart,
    BookingsStatesPieChart,
    DateButtons,
    vSelect
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
  },

  created () {
    this.getBookingAnalytics()
    this.getBookingStatesAPI();

    this.$store.dispatch('businessEvents/getBusinessEvents',{venueId: this.currentVenueId, params: {page: 1, perPage: 5}})
    window.addEventListener('changeLanguage', function(e){
      this.$store.dispatch('loader/loaderOn')
      Promise.all([ 
        this.getBookingAnalytics(),
        this.getBookingStatesAPI()
      ]).then(()=>{this.$store.dispatch('loader/loaderOff')}, this);
    }.bind(this));

  },

  methods: {
    getBookingStatesAPI () {
            
      this.$store.dispatch('loader/loaderOn')
      this.$store.dispatch('bookingStates/getBookingStatesArrayByArrayName', {arrayName: 'availableStatesToAgent'})
        .then((response) => {
          this.bookingStates = response.data.bookingStates;
          this.selectedStates = response.data.selectedStates;
        })
      .catch((error) => { console.log('get analysis errer', error) })
      .finally(() => {
        this.$store.dispatch('loader/loaderOff')
      })
      
    },
    getBookingAnalytics(){
      let params = new URLSearchParams();

      params.append('start_date',  moment(this.bookingsDateRange.start).format(this.dateFormat.input));
      params.append('end_date', moment(this.bookingsDateRange.end).format(this.dateFormat.input));
      params.append('booking_states', Object.values(this.selectedStates.map(state => state.name)));
      this.$store.dispatch('loader/loaderOn')
      axios.get(`/venues/${this.currentVenueId}/analytics/bookings?${params}`)
        .then((response) => {
          this.bookingAnalytics.totalPaidAmount = response.data.total_paid_amount+" S.R";
          this.bookingAnalytics.totalPrice = "Total Without VAT: " + response.data.total_price+" S.R";
          this.bookingAnalytics.totalPriceWithTax = response.data.total_price_with_tax+" S.R";
          this.bookingAnalytics.bookingsCount = response.data.count;
          this.bookingAnalytics.totalQosoorBookings = "From Eventful: " + response.data.total_qosoor_bookings;
          this.bookingAnalytics.totalQosoorCommission = response.data.total_qosoor_commission+" S.R";
          this.bookingAnalytics.totalRemainingAmount = "Total Remaining: " + response.data.total_remaining_amount;
          this.error = null;
        })
        .catch((error) => {
          this.error = error.response.data.error_message;
        })
        .finally(() => {
          this.$store.dispatch('loader/loaderOff')
        })
    },
    dateClicked(dateRange) {
      this.bookingsDateRange = dateRange;
      this.bookingDateRangeSubmited();
    },

    bookingDateRangeSubmited(){
      // console.log(this.bookingsDateRange.start);
      this.getBookingAnalytics()
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    }
  },
  watch: {
    bookingsDateRange: function() {
      this.bookingDateRangeSubmited();
    },
    selectedStates: function() {
      this.bookingDateRangeSubmited();
    }
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
