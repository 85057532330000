<template>
  <!-- DATES BUTTONS -->
    <div class="vx-row w-full mt-5"> 
        <div class="vx-col w-full m-0 p-0">
        <div id="container">
            <div class="tabs">
            <label class="tab">
                <input type="radio" name="tab-input" class="tab-input">
                <div :class="activeTab == 'currentMonth' ? 'active-tab' : 'tab-box'" @click="dateClicked('currentMonth')">{{$t('dateButtons.currentMonth')}}</div>
            </label>
            <label class="tab">
                <input type="radio" name="tab-input" class="tab-input">
                <div :class="activeTab == 'lastMonth' ? 'active-tab' : 'tab-box'" @click="dateClicked('lastMonth')">{{$t('dateButtons.lastMonth')}}</div>
            </label>
            <label class="tab">
                <input type="radio" name="tab-input" class="tab-input">
                <div :class="activeTab == 'currentWeek' ? 'active-tab' : 'tab-box'" @click="dateClicked('currentWeek')">{{$t('dateButtons.currentWeek')}}</div>
            </label>
            <label class="tab">
                <input type="radio" name="tab-input" class="tab-input">
                <div :class="activeTab == 'lastWeek' ? 'active-tab' : 'tab-box'" @click="dateClicked('lastWeek')">{{$t('dateButtons.lastWeek')}}</div>
            </label>
            <label class="tab">
                <input type="radio" name="tab-input" class="tab-input">
                <div :class="activeTab == 'currentDay' ? 'active-tab' : 'tab-box'" @click="dateClicked('currentDay')">{{$t('dateButtons.currentDay')}}</div>
            </label>
            <label class="tab">
                <input type="radio" name="tab-input" class="tab-input">
                <div :class="activeTab == 'lastDay' ? 'active-tab' : 'tab-box'" @click="dateClicked('lastDay')">{{$t('dateButtons.lastDay')}}</div>
            </label>
            <label class="tab">
                <input type="radio" name="tab-input" class="tab-input">
                <div :class="activeTab == 'currentYear' ? 'active-tab' : 'tab-box'" @click="dateClicked('currentYear')">{{$t('dateButtons.currentYear')}}</div>
            </label>
            <label class="tab">
                <input type="radio" name="tab-input" class="tab-input">
                <div :class="activeTab == 'lastYear' ? 'active-tab' : 'tab-box'" @click="dateClicked('lastYear')">{{$t('dateButtons.lastYear')}}</div>
            </label>
            </div>
        </div>
        </div>
    </div>
    <!--// DATES BUTTONS //-->
</template>

<script>
import moment from 'moment'
export default {

    data () {
        return {
            activeTab: 'currentMonth'
        }
    },
    methods: {
        dateClicked(clicked) {
            this.activeTab = clicked;
            switch (clicked) {
                case 'currentMonth':
                    this.$emit('dateClicked', {start: moment(moment().startOf('month').toDate()).format('YYYY-MM-DD'), end: moment(moment().endOf('month').toDate()).format('YYYY-MM-DD')})        
                    break;
                case 'lastMonth':
                    this.$emit('dateClicked', {start: moment(moment().subtract(1,'months').startOf('month').toDate()).format('YYYY-MM-DD'), end: moment(moment().subtract(1,'months').endOf('month').toDate()).format('YYYY-MM-DD')})        
                    break;
                case 'currentWeek':
                    this.$emit('dateClicked', {start: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'), end: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD')})        
                    break;
                case 'lastWeek':
                    this.$emit('dateClicked', {start: moment(moment().subtract(1,'week').startOf('week').toDate()).format('YYYY-MM-DD'), end: moment(moment().subtract(1,'week').endOf('week').toDate()).format('YYYY-MM-DD')})        
                    break;
                case 'currentDay':
                    this.$emit('dateClicked', {start: moment(moment().startOf('day').toDate()).format('YYYY-MM-DD'), end: moment(moment().endOf('day').toDate()).format('YYYY-MM-DD')})        
                    break;
                case 'lastDay':
                    this.$emit('dateClicked', {start: moment(moment().subtract(1,'day').startOf('day').toDate()).format('YYYY-MM-DD'), end: moment(moment().subtract(1,'day').endOf('day').toDate()).format('YYYY-MM-DD')})        
                    break;
                case 'currentYear':
                    this.$emit('dateClicked', {start: moment(moment().startOf('year').toDate()).format('YYYY-MM-DD'), end: moment(moment().endOf('year').toDate()).format('YYYY-MM-DD')})        
                    break;
                case 'lastYear':
                    this.$emit('dateClicked', {start: moment(moment().subtract(1,'year').startOf('year').toDate()).format('YYYY-MM-DD'), end: moment(moment().subtract(1,'year').endOf('year').toDate()).format('YYYY-MM-DD')})        
                    break;
                default:
                    break;
            }
            
            
        }
    }

}
</script>

<style lang="scss">

.tabs {
  --bar-color: #ffc870;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tabs > .tab {
  flex: 1;
  
  display: flex;
}

.tab:hover {
    color:var(--bar-color);
    cursor: pointer;
}

.tab > .tab-input {
  width: 0;
  height: 0;
  margin: 0;
  display: none;
}

.tab > .tab-box {
  padding: .5rem;
  width: 100%;
  text-align: center;
  transition: 0.5s;
  border-bottom: 5px solid rgba(0,0,0,0);
}

// .tab > .tab-input:checked + .tab-box {
//   border-color: var(--bar-color);
// }
.active-tab {
    padding: .5rem;
    width: 100%;
    text-align: center;
    transition: 0.5s;
    border-bottom: 5px solid rgba(0,0,0,0);
    border-color: var(--bar-color);
    color: var(--bar-color);
}
</style>