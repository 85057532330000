<!-- =========================================================================================
    File Name: ChartLineChart.vue
    Description: Create line chart
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card :title="$t('bookingsStatesPieChart')" class="mb-base h-full">
        <div class="mt-5">
            <chartjs-component-pie-chart :height="250" :chartData="data" :options="options" ref="the_chart" />
        </div>
    </vx-card>
</template>

<script>
import ChartjsComponentPieChart from '@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentPieChart.vue'
import axios from '@/axios'
import moment from 'moment'

export default {
  props: {
    bookingsDateRange: {
      type: Object
    },
    selectedStates: {
      type: Array
    }
  },
  components: {
    ChartjsComponentPieChart,

  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    selectedStatesKeys () {
      return this.selectedStates.map(state => state.name);
    }
  },
  data () {
    return {
      data: {},
      options: {
        display: true,
        legend: {
            display: false
        },
      }
    }
  },
  created() {
    this.getAnalysis();
  },
  methods: {
    getAnalysis () {
      let params = new URLSearchParams();

      params.append('start_date',  moment(this.bookingsDateRange.start).format('YYYY-MM-DD'));
      params.append('end_date', moment(this.bookingsDateRange.end).format('YYYY-MM-DD'));
      params.append('booking_states', Object.values(this.selectedStatesKeys));

      axios.get(`/venues/${this.currentVenueId}/analytics/bookingsStatesPieChart?${params}`)
        .then((response) => {
          this.data = response.data.pieChartData;
        })
      .catch((error) => { console.log('get analysis errer', error) })
      
    }
  },
  watch: {
    bookingsDateRange: function(newVal) {
      this.getAnalysis();
    },
    selectedStates: function() {
      this.getAnalysis();
    }
  }
}
</script>
